import Map from "./Components/Map/Map";
import Section from "../../UI/Components/Section/Section";
import ContactInfo from "./Components/ContactInfo/ContactInfo";
import styles from "./Contact.module.css";
import { useSlideChange } from "../../hooks/useChangeSlide";
import { useLoadPage } from "../../hooks/useLoadPage";
import { ContactModel, ContactTextsModel } from "./Models/ContactModel";

type Props = {
  texts: ContactTextsModel;
  data: ContactModel;
  offset: number;
};

const Contact = ({ texts, data, offset }: Props) => {
  const { wheelHandler, handleTouchStart, sectionRef } = useSlideChange({
    styles: styles,
    prevPage: "/my-work",
    offset: offset,
    last: true,
  });

  useLoadPage({ path: "/contact", offset: offset });

  const content = (
    <div className={styles.text}>
      <h2>{texts.headline}</h2>
      {texts.text.map((c, i) => (
        <p key={i}>{c}</p>
      ))}
    </div>
  );

  return (
    <Section
      className={styles.contact}
      wheel={wheelHandler}
      onTouchStart={handleTouchStart}
      ref={sectionRef}
    >
      {content}
      <ContactInfo
        className={styles["contact-info"]}
        address={data.address}
        emailAddress={data.emailAddress}
        linkedin={data.linkedin}
        phoneNumber={data.phoneNumber}
      />
      <div className={styles.map}>
        <Map lat={43.52547} lng={0.16105} />
      </div>
    </Section>
  );
};

export default Contact;
