import {
  ContactModel,
  ContactStructure,
  ContactTextsModel,
} from "../Models/ContactModel";

export const data: ContactModel = {
  address: {
    cityName: "Marciac",
    postNumber: "32230",
    streetNumber: "15",
    companyName: "Jan Majerníček",
    streetName: "rue Joseph Abeilhe",
  },
  emailAddress: "jan@majernicek.cz",
  // linkedin: "https://www.linkedin.com/in/majernicek/",
  phoneNumber: ["+33 06 18 01 45 70", "+420 607 124 482"],
};

const contactTextsCz: ContactTextsModel = {
  headline: "Jsem tu pro Vás",
  text: [
    "Rád si vyslechnu Vaše nápady, plány, přání a bude mi ctí Vám je pomoci realizovat!",
  ],
};

const contactTextsEn: ContactTextsModel = {
  headline: "I'm here for you",
  text: [
    "Always happy to hear your ideas, wishes, plans and willing to help you with your project or promoting your company!",
  ],
};

const contactTextsFr: ContactTextsModel = {
  headline: "Je suis là pour Vous",
  text: [
    "Toujours heureux d'entendre vos idées, vos souhaits, vos projets et prêt à vous aider à les réaliser!",
  ],
};

export const contactTextsData: ContactStructure[] = [
  {
    data: contactTextsEn,
    lang: "en",
  },
  {
    data: contactTextsCz,
    lang: "cs",
  },
  {
    data: contactTextsFr,
    lang: "fr",
  },
];
