import { MyWorkModel } from "../Models/myWorkModel";

export const data: MyWorkModel[] = [
  {
    image: "https://tenebris.cz/soubory/toradovci.png",
    title: "Toradovci.eu",
    content: [
      "Website I made as a wedding invitation for a friend of mine. I saw it as an opportunity to try a new approach, create a new layout and another bunch of reusable components and built it from a scratch. Not a big project, but it was definitely fun.",
      "Website is client-side only. No server side code was needed, the guests' questionnaires have been handled by Google forms due to time pressure.",
    ],
    hyperlink: "https://toradovci.eu/",
    lang: "en",
  },
  {
    image: "https://tenebris.cz/soubory/toradovci.png",
    title: "Toradovci.eu",
    content: [
      "Svatební stránka, kterou jsem vytvořil pro svého kamaráda. Vyzkoušel jsem si na ní nový přístup k tvorbě layoutu a při jejím vytváření myslel na recyklovatelnost komponent do dalších projektů. Jednoduchý, ale zábavný projekt.",
      "Stránka je pouze client-side, není napojená na žádný backend. V případě tohoto projektu to nebylo nutné.",
    ],
    hyperlink: "https://toradovci.eu/",
    lang: "cs",
  },
  {
    image: "https://tenebris.cz/soubory/toradovci.png",
    title: "Toradovci.eu",
    content: [
      "Site web que j'ai créé pour l'invitation au mariage d'un de mes amis. J'y ai vu l'occasion d'essayer une nouvelle approche, de créer une nouvelle mise en page et un autre ensemble de composants réutilisables et de le construire à partir de zéro. Ce n'est pas un gros projet, mais c'était vraiment amusant.",
      "Le site web n'existe que côté client. Aucun code côté serveur n'a été nécessaire, les questionnaires des visiteurs ont été gérés par Google forms par manque de temps.",
    ],
    hyperlink: "https://toradovci.eu/",
    lang: "fr",
  },
  {
    image: "https://tenebris.cz/soubory/zn.png",
    title: "Žižkovská noc",
    content: [
      "From 2017 to 2022, I've been in charge of the biggest Czech club festival's website. Each year's edition got a brand new design and each year I got better.",
      "The presented version is the latest one, when I finally switched from cumbersome and outdated PHP view based approach to much more modern React.js + Node.js solution. The result is swift and lightweight one page React application, using React router for navigation.",
      "A part of it was originally also a webshop prototype, the idea we abandoned under way due to some legal and logistical issues. The website was also originally meant to have a custom CMS. Both of these ideas remain to be realized in future projects.",
    ],
    lang: "en",
  },
  {
    image: "https://tenebris.cz/soubory/zn.png",
    title: "Žižkovská noc",
    content: [
      "V letech 2017 - 2022 jsem byl zodpovědný za webové stránky největšího českého klubového festivalu. Každý rok dostal web nový kabát a každý rok byl o kousek lepší.",
      "Prezentovaná verze je tou poslední, na níž jsem pracoval. Zahodil jsem starou a těžkopádnou PHP verzi a vytvořil úplně nový React.js projekt s Node.js API. Jde o velmi lehkou a svižnou jednostránkovou webovou aplikaci, která si v navigaci pomáhá React Routerem, čímž vytváří iluzi více sekcí.",
      "Součástí webu měl být původně také e-shop a uživatelská administrace, ovšem z časových, finančních a právních důvodů bylo od obojího upuštěno. Kód vytvořený pro tyto účely bude pravděpodobně použit v příštích projektech.",
    ],
    lang: "cs",
  },
  {
    image: "https://tenebris.cz/soubory/zn.png",
    title: "Žižkovská noc",
    content: [
      "De 2017 à 2022, j'ai été en charge du site web du plus grand festival de clubs tchèque. Chaque année, l'édition a bénéficié d'un tout nouveau design et chaque année, je me suis amélioré.",
      "La version présentée est la plus récente, lorsque j'ai finalement abandonné l'encombrante et désuète approche PHP basée sur les vues pour une solution beaucoup plus moderne React.js + Node.js. Le résultat est une application React rapide et légère, utilisant le routeur React pour la navigation.",
      "Une partie du site était à l'origine un prototype de boutique en ligne, idée que nous avons abandonnée en cours de route en raison de problèmes juridiques et logistiques. Le site web devait également être doté d'un système de gestion de contenu (CMS) personnalisé. Ces deux idées restent à réaliser dans des projets futurs.",
    ],
    lang: "fr",
  },
];
