import { MenuModel, MenuStructure } from "../Models/MenuModel";

const menuEn: MenuModel = {
  landing: "Welcome",
  about: "About me",
  mywork: "My work",
  contact: "Contact",
};

const menuCz: MenuModel = {
  landing: "Domů",
  about: "O mně",
  mywork: "Moje portfolio",
  contact: "Kontakt",
};

const menuFr: MenuModel = {
  landing: "Bienvenue",
  about: "À propos de moi",
  mywork: "Mon portfolio",
  contact: "Contact",
};

export const data: MenuStructure[] = [
  {
    menu: menuEn,
    lang: "en",
  },
  {
    menu: menuCz,
    lang: "cs",
  },
  {
    menu: menuFr,
    lang: "fr",
  },
];
