import { useEffect, useState, useContext } from "react";
import { Route, Routes } from "react-router-dom";

import LocationContext from "./store/locationContext";
import TopBar from "./UI/Components/Menu/TopBar";
import Wrapper from "./UI/Components/Wrapper/Wrapper";
import Scrollbar from "./UI/Components/Scrollbar/Scrollbar";
import Landing from "./pages/Landing/Landing";
import About from "./pages/About/About";
import MyWork from "./pages/MyWork/MyWork";
import Contact from "./pages/Contact/Contact";

import { MyWorkModel } from "./pages/MyWork/Models/myWorkModel";
import { AboutModel } from "./pages/About/Models/aboutModel";
import { LandingModel } from "./pages/Landing/Models/LandingStructure";
import {
  ContactModel,
  ContactTextsModel,
} from "./pages/Contact/Models/ContactModel";
import { MenuModel } from "./UI/Components/Menu/Models/MenuModel";

// temporary imports, later will be replaced by API call
import { landingData as landingDataSource } from "./pages/Landing/data/LandingData";
import { data as aboutDataSource } from "./pages/About/Data/aboutData";
import { data as myWorkDataSource } from "./pages/MyWork/Data/mockupData";
import { data as contactDataSource } from "./pages/Contact/Data/ContactData";
import { contactTextsData as contactTextsDataSource } from "./pages/Contact/Data/ContactData";
import { data as menuDataSource } from "./UI/Components/Menu/Data/menuData";

import "./App.css";
import Spinner from "./pages/Landing/Components/Spinner/Spinner";

function App() {
  const ctx = useContext(LocationContext);
  /* For later use, now unnecessary */
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState<string | null>(null);

  /* Fetched data from API will come here and be passed down to the pages*/
  // All of this shit could be probably refactored into something more clever, but I won't do it as it will be replaced by proper fetch later anyway
  const [aboutData, setAboutData] = useState<AboutModel[]>([]);
  const [myWorkData, setMyWorkData] = useState<MyWorkModel[]>([]);
  const [landingData, setLandingData] = useState<LandingModel>();
  const [contactData, setContactData] = useState<ContactModel>({});
  const [contactTextsData, setContactTextsData] = useState<ContactTextsModel>();
  const [menuData, setMenuData] = useState<MenuModel>();

  useEffect(() => {
    const fetchData = async () => {
      // fetch data from API logic will come here later
      setContactData(contactDataSource);

      const fetchedAboutData = aboutDataSource.filter(
        (e) => e.lang === ctx.lang
      );
      if (fetchedAboutData) {
        setAboutData(fetchedAboutData);
      }

      const fetchedMyWorkData = myWorkDataSource.filter(
        (e) => e.lang === ctx.lang
      );
      if (fetchedMyWorkData) {
        setMyWorkData(fetchedMyWorkData);
      }

      const fetchedLandingData = landingDataSource.find(
        (e) => e.lang === ctx.lang
      )?.data;
      if (fetchedLandingData) {
        setLandingData(fetchedLandingData);
      } else {
        throw new Error("Couldn't get landing page data");
      }

      const fetchedContactTextsData = contactTextsDataSource.find(
        (e) => e.lang === ctx.lang
      )?.data;
      if (fetchedContactTextsData) {
        setContactTextsData(fetchedContactTextsData);
      } else {
        throw new Error("Couldn't get contacts page data");
      }

      const fetchedMenuData = menuDataSource.find(
        (e) => e.lang === ctx.lang
      )?.menu;
      if (fetchedMenuData) {
        setMenuData(fetchedMenuData);
      } else {
        throw new Error("Couldn't get menu data");
      }

      /* For now, we have the timer. After adding real API fetch, change it to real loading screen */
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 6000);
    };

    try {
      fetchData();
    } catch (error) {
      let message = "Unknown Error";
      if (error instanceof Error) message = error.message;
      else message = String(error);
      setIsLoading(false);
      setHasError(message);
    }
  }, [ctx.lang, landingData]);

  //TODO: #7 Mobile browser still scrolls, problem with top-bar and it's width
  const content = isLoading ? (
    <Spinner />
  ) : (
    <>
      <TopBar
        aboutLength={aboutData.length}
        myWorkLength={myWorkData.length}
        data={menuData!}
      />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Landing data={landingData!} />} />
          <Route path="/about-me" element={<About data={aboutData} />} />
          <Route
            path="/my-work"
            element={
              <MyWork data={myWorkData} aboutDataLength={aboutData.length} />
            }
          />
          <Route
            path="/contact"
            element={
              <Contact
                offset={1 + aboutData.length + myWorkData.length}
                data={contactData}
                texts={contactTextsData!}
              />
            }
          />
        </Routes>
        <Scrollbar
          aboutSlideCount={aboutData.length}
          myWorkSlideCount={myWorkData.length}
        />
      </Wrapper>
    </>
  );

  return content;
}

export default App;
