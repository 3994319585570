import { useState, useEffect } from "react";

import { Language, languages, LocContextProps } from "./locationContext";
import LocationContext from "./locationContext";

type Props = {
  children: React.ReactNode;
};

const LocationProvider = ({ children }: Props) => {
  const [path, setPath] = useState("/");
  const [slide, setSlide] = useState(0);
  const [touched, setTouched] = useState(false);
  const [lang, setLang] = useState<Language>("en");

  // If the language matches our selection, this is the function to change it
  const changeLang = (inputLang: string) => {
    if (typeof inputLang === "string" && languages.includes(inputLang)) {
      setLang(inputLang as Language);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams && queryParams.get("lang")) {
      changeLang(queryParams.get("lang")!);
    } else {
      const userLang = navigator.language.substring(0, 2);
      changeLang(userLang);
    }
  }, []);

  const locContext: LocContextProps = {
    touched: touched,
    path: path,
    slide: slide,
    lang: lang,
    setTouched: setTouched,
    changePath: setPath,
    changeSlide: setSlide,
    changeLang: changeLang,
  };

  return (
    <LocationContext.Provider value={locContext}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
