import styles from "./Slide.module.css";

type Props = {
  children?: React.ReactNode;
  className?: string;
  imgUrl?: string;
  imgAlt?: string;
};

const Slide = ({ children, className, imgUrl, imgAlt }: Props) => {
  const classes = styles.text + (className ? " " + className : "");

  const image = imgUrl ? (
    <div className={styles.image}>
      <img src={imgUrl} alt={imgAlt} />
    </div>
  ) : null;

  return (
    <>
      {image}
      <div className={classes}>{children}</div>
    </>
  );
};

export default Slide;
