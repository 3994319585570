import { useRef, useEffect, useState } from "react";

import CloseButton from "./CloseButton";
import GoToTheWeb from "./GoToTheWeb";

import styles from "./ModalOverlay.module.css";

type Props = {
  children: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLDivElement>;
  targetWebsite?: string;
};

const ModalOverlay = ({ children, onClose, targetWebsite }: Props) => {
  const [active, setActive] = useState(false);
  const [buttonActive, buttonSetActive] = useState(false);
  const closeRef = useRef<HTMLDivElement>(null);
  const goToRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active) {
      closeRef.current?.classList.add(styles["closed__active"]);
      goToRef.current?.classList.add(styles["goto__active"]);
    } else {
      closeRef.current?.classList.remove(styles["closed__active"]);
      goToRef.current?.classList.remove(styles["goto__active"]);
    }
  }, [active]);

  useEffect(() => {
    if (buttonActive) {
      overlayRef.current?.classList.add(styles["overlay__active"]);
    } else {
      overlayRef.current?.classList.remove(styles["overlay__active"]);
    }
  }, [buttonActive]);

  const contentClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const mouseOverHandler = () => {
    setActive(true);
  };

  const mouseOutHandler = () => {
    setActive(false);
  };

  const buttonOverHandler = () => {
    buttonSetActive(true);
  };

  const buttonOutHandler = () => {
    buttonSetActive(false);
  };

  const goToTheWebHandler = () => {
    if (targetWebsite) {
      window.open(targetWebsite, "_blank");
    }
  };

  return (
    <div className={styles.modal} onClick={onClose}>
      <div
        className={styles.content}
        onClick={contentClickHandler}
        onMouseOver={mouseOverHandler}
        onMouseOut={mouseOutHandler}
      >
        <div className={styles.overlay} ref={overlayRef}>
          <CloseButton onClick={onClose} ref={closeRef} />
        </div>
        {targetWebsite && (
          <>
            <GoToTheWeb
              ref={goToRef}
              onClick={goToTheWebHandler}
              onMouseOver={buttonOverHandler}
              onMouseOut={buttonOutHandler}
            />
          </>
        )}
        {children}
      </div>
    </div>
  );
};

export default ModalOverlay;
