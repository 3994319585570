import { LandingStructure, LandingModel } from "../Models/LandingStructure";

const LandingDataCz: LandingModel = {
  myNameIs: "Jsem Jan Majerníček",
  skills: ["webový vývojář", "produkční", "muzikant"],
  located: "Žijící ve francouzské vesničce Marciac",
  text: [
    "Sháníte někoho, kdo Vám vyrobí webové stránky na míru? Externího vývojáře pro Váš projekt či firmu? Potřebujete zkušeného produkčního pro váš festival? Nebo jste sem zabloudili náhodou?",
    "V každém případě Vás vřele vítám na svých stránkách",
  ],
};

const LandingDataEn: LandingModel = {
  myNameIs: "I'm Jan Majerníček",
  skills: ["web developer", "event producer", "musician"],
  located: "Based in Marciac, France",
  text: [
    "Are you looking for someone to create your personal, hobby or business web page? Searching for an experienced external project manager / producer for your venue or festival? Or are you just curious?",
    "In any case, welcome to my page!",
  ],
};

const LandingDataFr: LandingModel = {
  myNameIs: "Je suis Jan Majerníček",
  skills: ["développeur web", "producteur d'événements", "musicien"],
  located: "Basé à Marciac, France",
  text: [
    "Cherchez-vous quelqu'un pour créer votre propre page web? Quelqu'un qui a de l'expérience dans la production culturelle pour votre festivalle ou salle? Ou êtes-vous simplement curieux?",
    "En tout cas, bienvenue sur ma page!",
  ],
};

export const landingData: LandingStructure[] = [
  {
    data: LandingDataEn,
    lang: "en"
  },
  {
    data: LandingDataCz,
    lang: "cs"
  },
  {
    data: LandingDataFr,
    lang: "fr"
  }
]