/* To be replaced by proper database - json file later */

import { AboutModel } from "../Models/aboutModel";

export const data: AboutModel[] = [
  {
    title: "My story",
    image: "https://tenebris.cz/soubory/my-face.jpg",
    content: [
      "Born in 1992 in Central Bohemia, Czechoslovakia. From early childhood, my passion for everything technical and interactive was more than obvious, along with a keen interest in music and languages.",
      "I started coding my first websites in elementary school. This continued with my studies at SPŠE Ječná Secondary school of electroengineering and computer science, where I also learned the basics of many programming languages and concepts, further fueling my interest.",
      "Around the same time I started playing guitar and organizing local concerts. This new hobby grew quickly and almost before I knew it, I was a well-established local promoter while still pursuing my studies, now at Charles University in Prague.",
      "Although I left the university after two years to focus more on my career in culture, it still gave me a solid foundation for my future IT projects, a passion I never truly abandoned.",
      "In 2018 I moved to Marciac, France, and the rest is now. I'm here, ready to build a modern, fresh looking and swift website for you, or to offer my experience and an innovative approach to your venue or festival.",
    ],
    lang: "en",
  },
  {
    title: "Můj příběh",
    image: "https://tenebris.cz/soubory/my-face.jpg",
    content: [
      "Narodil jsem se v roce 1992 ve středních Čechách. Od útlého dětství byl zřejmý můj zájem o vše technické, interaktivní, ale rovněž i o muziku nebo jazyky.",
      "S programováním jsem poprvé přišel do styku již na základní škole. Na Střední průmyslové škole elektrotechnické v Ječné jsem získal solidní základy více programovacích jazyků a konceptů a pomalu se začal pouštět do vážnějších projektů.",
      "Zhruba v té samé době jsem začal hrát na kytaru a pořádat koncerty. Zejména druhé jmenované mi rychle přirostlo k srdci, velmi rychle jsem se tak stal aktivním promotérem. To vše stále při studiu, které po ukončení SŠ pokračovalo na MFF UK.",
      "Ačkoliv jsem studia po dvou letech zanechal z důvodu přílišného pracovního vytížení, studium informatiky na jedné z nejprestižnějších fakult v oboru mi i tak dalo spoustu důležitých stavebních kamenů pro moje budoucí IT projekty.",
      "V roce 2018 jsem se přestěhoval do Francie, kde žiji doposud. Jako webový vývojář a produkční na volné noze jsem k dispozici pro zajímavé projekty v obou oborech.",
    ],
    lang: "cs",
  },
  {
    title: "Mon histoire",
    image: "https://tenebris.cz/soubory/my-face.jpg",
    content: [
      "Né en 1992 en Bohême centrale, en Tchécoslovaquie. Dès mon enfance, ma passion pour tout ce qui est technique et interactif était plus qu'évidente, tout comme mon intérêt pour la musique et les langues.",
      "J'ai commencé à coder mes premiers sites web à l'école primaire. Cela s'est poursuivi avec mes études à l'école secondaire SPŠE Ječná d'électro-ingénierie et d'informatique, où j'ai également appris les bases de nombreux langages et concepts de programmation, ce qui a encore alimenté mon intérêt.",
      "À la même époque, j'ai commencé à jouer de la guitare et à organiser des concerts locaux. Ce nouveau hobby a rapidement pris de l'ampleur et, peu de temps après, je suis devenu un promoteur local bien établi, tout en poursuivant mes études, actuellement à l'université Charles de Prague.",
      "Bien que j'aie quitté l'université après deux ans pour me concentrer davantage sur ma carrière dans la culture, elle m'a tout de même donné une base solide pour mes futurs projets informatiques, une passion que je n'ai jamais vraiment abandonnée.",
      "En 2018, j'ai déménagé à Marciac, en France, et le reste, c'est maintenant. Je suis ici, prêt à construire un site web moderne, frais et rapide pour vous, ou à offrir mon expérience et une approche innovante à votre salle ou festival.",
    ],
    lang: "fr",
  },
  {
    title: "Web developer",
    image: "https://tenebris.cz/soubory/my-face.png",
    content: [
      "The year was 2004, maybe 2005. I was a kid of the first internet generation, before the age of social networks, when having your own blog or site was socially essential. I felt really limited by the early, not very well optimized or fast CMS and WYSIWIG editors, so I decided to start learning HTML and CSS to be able to create just about anything I could imagine back then.",
      "Later on, I understood that simple static website is sometimes just not enough, especially when creating a site for someone else. I started learning PHP and MySQL and during my high school studies, I even developed my first simple form based CMS, so that my clients could add, delete or change content on their own. I also tried .NET briefly, but due to poor hosting provider support back then, I stayed with PHP.",
      "Since then, I made a long journey. Now I'm working mainly with React.js and Node.js, creating separate front-end and API. I create modern dynamic web applications, tailored carefuly for your needs and taste.",
    ],
    lang: "en",
  },
  {
    title: "Webový vývojář",
    image: "https://tenebris.cz/soubory/my-face.png",
    content: [
      "Psal se rok 2004 nebo 2005. Pro moji generaci, první odchované internetem, bylo nezbytné se na sítích nějakým způsobem prezentovat. Masivní rozšíření sociálních sítí přišlo až o něco později, v té době měl každý svůj blog nebo webovou stránku vytvořenou v nějakém redakčním systému. Obojí mi přišlo značně limitující, rozhodl jsem se tedy naučit HTML a CSS, abych si pro sebe mohl vytvořit, cokoliv mě v té době dokázalo napadnout.",
      "Později, během středoškolských studií, jsem pochopil, že statické stránky bez možnosti uživatelské správy nejsou nic moc, zvlášť pokud je nevyvíjíte jako koníček, ale pro klienty. Své znalosti jsem tedy doplnil o PHP a MySQL a na několika webech jsem dokonce provozoval vlastní, na formulářích založený jednoduchý CMS systém. Zlehka jsem přičichl i k .NET, vzhledem k jeho chabé tehdejší podpoře u mainstreamových hostingů jsem ale nakonec zůstal u PHP.",
      "Od té doby jsem ušel dlouhou cestu. Nyní vyvíjím webové stránky zejména v kombinaci React.js na front-endu s Node.js REST API. Snažím se vytvářet moderní, rychlé a dynamické webové aplikace přesně dle chuti a potřeb zákazníka.",
    ],
    lang: "cs",
  },
  {
    title: "Développeur web",
    image: "https://tenebris.cz/soubory/my-face.png",
    content: [
      "Nous étions en 2004, peut-être en 2005. J'étais un enfant de la première génération de l'internet, avant l'ère des réseaux sociaux, quand avoir son propre blog ou site était socialement essentiel. Je me sentais vraiment limité par les premiers CMS et éditeurs WYSIWIG, qui n'étaient pas très bien optimisés ou rapides, et j'ai donc décidé de commencer à apprendre le HTML et le CSS pour pouvoir créer à peu près tout ce que je pouvais imaginer à l'époque.",
      "Plus tard, j'ai compris qu'un simple site web statique n'était parfois pas suffisant, surtout lorsqu'il s'agit de créer un site pour quelqu'un d'autre. J'ai commencé à apprendre le PHP et le MySQL et, pendant mes études secondaires, j'ai même développé mon premier CMS simple basé sur des formulaires, afin que mes clients puissent ajouter, supprimer ou modifier le contenu par eux-mêmes. J'ai également essayé brièvement .NET, mais en raison de la mauvaise assistance de l'hébergeur à l'époque, je suis resté avec PHP.",
      "Depuis, j'ai fait un long voyage. Aujourd'hui, je travaille principalement avec React.js et Node.js, en créant des front-end et des API distincts. Je crée des applications web dynamiques modernes, adaptées soigneusement à vos besoins et à vos goûts.",
    ],
    lang: "fr",
  },
  {
    title: "Event producer",
    image: "https://tenebris.cz/soubory/alina.jpg",
    content: [
      "I started organizing local shows in 2010. First only for my band, but soon I joined the RockPro promoter collective and participated on multiple club festivals, band contest and a few Prague shows of international tours.",
      "From 2012 to 2014, I worked as a production manager, booker, promoter and dramaturgist for Exit-us Club Prague. It gave me the opportunity to work on bigger shows with more renowned artists like Orphaned Land, Inquisition or Jinjer.",
      "In 2015, I spent a year organizing cultural events on my own, using Infernalismus art group brand. Those were not just concerts, we combined metal music with poetry, dance, fine arts exhibitions and other types of artistic performance.",
      "A year after, in 2016, I joined Žižkovská noc, the biggest club festival in the Czech republic. First as a stage manager, later as a production crew in charge of logistics and IT and in 2017, I became a head of production. In 2022, I decided to part ways with the festival.",
    ],
    lang: "en",
  },
  {
    title: "Produkční",
    image: "https://tenebris.cz/soubory/alina.jpg",
    content: [
      "Koncerty jsem začal pořádat v roce 2010. Nejprve pouze pro svoji kapelu, brzy jsem se ale připojil k promotérskému kolektivu RockPro a podílel se na několika klubových festivalech, soutěži kapel nebo několika pražských zastávkách mezinárodních turné zahraničních kapel.",
      "V letech 2012 - 2014 jsem pracoval jako produkční, dramaturg a booker pro pražský klub Exit-us. Díky tomuto zázemí jsem měl možnost pořádat koncerty větších a zajímavějších kapel, jako například Orphaned Land, Inquisition nebo Jinjer.",
      "V roce 2015 jsem si pod hlavičkou umělecké skupiny Infernalismus uspořádat několik akcí po celé ČR, kdy se nejednalo o pouhé koncerty. Metalovou muziku doplňovala poezie, tanec, výstavy a různá další umělecká vystoupení.",
      "O rok později jsem vstoupil do Žižkovské noci, největšího českého klubového festivalu. Nejprve jako stage manager, později jako produkční, webmaster a dispečer logistiky a od roku 2017 jsem byl vedoucím produkce až do roku 2022, kdy jsem festival opustil.",
    ],
    lang: "cs",
  },
  {
    title: "Producteur d'événements",
    image: "https://tenebris.cz/soubory/alina.jpg",
    content: [
      "J'ai commencé à organiser des concerts locaux en 2010. D'abord uniquement pour mon groupe, mais j'ai rapidement rejoint le collectif de promoteurs RockPro et j'ai participé à de nombreux festivals de clubs, à des concours de groupes et à quelques concerts à Prague dans le cadre de tournées internationales.",
      "De 2012 à 2014, j'ai travaillé comme directeur de production, booker, promoteur et dramaturge pour Exit-us Club Prague. Cela m'a donné l'opportunité de travailler sur des spectacles plus importants avec des artistes plus renommés comme Orphaned Land, Inquisition ou Jinjer.",
      "En 2015, j'ai passé un an à organiser des événements culturels par moi-même, en utilisant la marque du groupe artistique Infernalismus. Il ne s'agissait pas seulement de concerts, nous combinions la musique métal avec de la poésie, de la danse, des expositions de beaux-arts et d'autres types de performances artistiques.",
      "Un an plus tard, en 2016, j'ai rejoint Žižkovská noc, le plus grand festival de clubs de la République tchèque. D'abord en tant que responsable de scène, puis en tant qu'équipe de production chargée de la logistique et de l'informatique et, en 2017, je suis devenu chef de production. En 2022, j'ai décidé de quitter le festival.",
    ],
    lang: "fr",
  },
];
