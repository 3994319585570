import { useState } from "react";

import useScreenResolution from "../../../hooks/useResolution";
import Burger from "../Burger/Burger";
import Logo from "../Logo/Logo";
import Menu from "./Menu";
import { MenuModel } from "./Models/MenuModel";
import styles from "./TopBar.module.css";

type Props = {
  aboutLength: number;
  myWorkLength: number;
  data: MenuModel;
};

const TopBar = ({ aboutLength, myWorkLength, data }: Props) => {
  // Logo src will later come from backend too, so that we can change it from the admin interface
  const [burgerOpen, setBurgerOpen] = useState(false);
  const { width, height } = useScreenResolution();

  const burgerOpenHandler = () => {
    setBurgerOpen((prevState) => {
      return !prevState;
    });
  };

  const burgerCloseHandler = () => {
    if (burgerOpen) {
      setBurgerOpen(false);
    }
  };

  const mobile = width < 750 || (width < 1000 && height < 580);
  const burger = (
    <Burger burgerActive={burgerOpen} burgerClickHandler={burgerOpenHandler} />
  );

  return (
    <div className={styles.topBar}>
      <Logo
        src="https://www.tenebris.cz/images/jm-logo.svg"
        mobileMenuHandler={burgerCloseHandler}
      />
      <Menu
        data={data}
        aboutLength={aboutLength}
        myWorkLength={myWorkLength}
        mobile={mobile}
        active={burgerOpen}
        mobileMenuHandler={burgerCloseHandler}
      />
      {mobile && burger}
    </div>
  );
};

export default TopBar;
