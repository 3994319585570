import { createContext } from "react";

// Maybe should make a model for this?
export type Language = "en" | "fr" | "cs";
export const languages = ["en", "fr", "cs"];
export interface LocContextProps {
  touched: boolean;
  path: string;
  slide: number;
  lang: Language;
  setTouched: (touched: boolean) => void;
  changePath: (path: string) => void;
  changeSlide: (slide: number) => void;
  changeLang: (inputLang: string) => void;
}

const LocationContext = createContext<LocContextProps>({
  touched: false,
  path: "/",
  slide: 0,
  lang: "en",
  setTouched: (touched: boolean) => {},
  changePath: (path: string) => {},
  changeSlide: (slide: number) => {},
  changeLang: (inputLang: string) => {},
});

export default LocationContext;
